import { inject, Injectable, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

export type ScriptOptions = {
  id: string;
  targetElement?: HTMLElement;
  onLoadedListener?: () => void;
  onExistsListener?: () => void;
} & ({ src: string; body?: never } | { src?: never; body: string });

@Injectable({
  providedIn: 'root',
})
export class ScriptService {
  private readonly rendererFactory = inject(RendererFactory2);
  private readonly renderer = this.rendererFactory.createRenderer(null, null);
  private readonly abortControllers = new Map<string, AbortController>();
  private readonly document = inject(DOCUMENT);

  createAndAppend(options: ScriptOptions): void {
    const script = this.getScriptById(options.id);

    if (script) {
      options.onExistsListener && options.onExistsListener();

      return;
    }

    this.append(this.create(options), options);
  }

  create(options: ScriptOptions): HTMLScriptElement | null {
    if (this.getScriptById(options.id)) {
      return null;
    }

    const script = this.renderer.createElement('script') as HTMLScriptElement;
    script.defer = true;
    script.id = options.id;

    if (options.src) {
      script.src = options.src;
    } else if (options.body) {
      script.append(options.body);
    }

    if (options.onLoadedListener) {
      const controller = new AbortController();
      this.abortControllers.set(options.id, controller);
      script.addEventListener('load', options.onLoadedListener, {
        passive: true,
        signal: controller.signal,
      });
    }

    return script;
  }

  append(script: HTMLScriptElement | null, options: ScriptOptions): void {
    if (!script) {
      return;
    }

    if (this.getScriptById(options.id)) {
      options.onExistsListener && options.onExistsListener();

      return;
    }

    this.renderer.appendChild(options.targetElement ?? this.document.body, script);
  }

  remove(id: string): void {
    this.abortControllers.get(id)?.abort();
    const script = this.getScriptById(id);

    if (script) {
      this.renderer.removeChild(script.parentElement, script);
    }
  }

  private getScriptById(id: string): HTMLScriptElement | null {
    return this.document.getElementById(id) as HTMLScriptElement | null;
  }
}
